import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueCookie from 'vue-cookie'; 
import ZoomOnHover from "vue-zoom-on-hover";
import css from  "../public/css/home.css";
import axios from 'axios';
import config from './json/config.json';
import Router from './router/index';
import VueSnip from 'vue-snip'


let mantenimiento = '';

Vue.use(VueSnip)
Vue.config.productionTip = false
Vue.use(VueCookie); 
Vue.use(ZoomOnHover); 


export default new Vue ({
  data(){
    return{
      matenimiento:'',
    }
  },
  async created(){
    await axios.get(config.apiAmoresens + '/mantenimiento/mantenimiento')
      .then(res=>{
        mantenimiento = res.data.data[0].activo;
      }).catch(err=>{
        console.log(err);
      })
      if (mantenimiento==false) {
        new Vue({
          css,
          router,
          vuetify,
          render: h => h(App)
        }).$mount('#app')
        
      }else if(mantenimiento==true){
       const router = Router
        new Vue({
          css,
          router,
          vuetify,
          render: h => h(App)
        }).$mount('#app')
      }
  }
})